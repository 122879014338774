import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects/Projects"

const ProjectsPage = ({
  data: { allMdx: { nodes: projects } }
}) => {
  return (
    <Layout>
      <section className="projects-page">
        <Projects projects={projects} title="All Projects" />
      </section>
    </Layout>
  )
}

export const query = graphql`
    {
        allMdx(
            filter: {fileAbsolutePath: {regex: "/content/projects/"}},
            sort: { fields: frontmatter___id }
        ) {
            nodes {
                frontmatter {
                    id
                    title
                    description
                    stack {
                        id
                        text
                        icon
                    }
                    github_link
                    hosted_link
                    image {
                        id
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`

export default ProjectsPage

